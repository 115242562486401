@font-face {
    font-family: SegoeRegular;
    src: url("./Fonts/Segoe/segoeui.ttf");
}

@font-face {
    font-family: SegoeBlack;
    src: url("./Fonts/Segoe/segoeuib.ttf");
}

@font-face {
    font-family: SegoeSemiBold;
    src: url("./Fonts/Segoe/seguisb.ttf");
}

@font-face {
    font-family: SegoeSemiBoldItalic;
    src: url("./Fonts/Segoe/seguisbi.ttf");
}

@font-face {
    font-family: SegoePrintBold;
    src: url("./Fonts/Segoe/segoeprb.ttf");
}

@font-face {
    font-family: SegoeBold;
    src: url("./Fonts/Segoe/segoeuib.ttf");
}

:root {
    --Regularfont: SegoeRegular;
    --Blackfont: SegoeBlack;
    --SemiBoldfont: SegoeSemiBold;
    --SemiBoldItalicfont: SegoeSemiBoldItalic;
    --PrintBoldfont: SegoePrintBold;
    --Boldfont: SegoeBold;
}

.PrintBoldfont {
    font-family: var(--PrintBoldfont);
}

.Regularfont {
    font-family: var(--Regularfont);
}
.SemiBold {
    font-family: var(--SemiBoldfont);
}
.SemiBoldItalic {
    font-family: var(--SemiBoldItalicfont);
}
.Blackfont {
    font-family: var(--Blackfont);
}
.Boldfont {
    font-family: var(--Boldfont);
}

.BrandingButton {
    border: 2px solid rgba(0, 0, 0, 0);
    @apply cursor-pointer bg-[#A1A3EF] text-white;
}

.RemoveDateIcon::-webkit-inner-spin-button,
.RemoveDateIcon::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.RemoveInputNumArrows::-webkit-inner-spin-button,
.RemoveInputNumArrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: inherit;
    -moz-appearance: textfield;
    margin: 0;
}
.sun-editor .se-wrapper {
    overflow-y: scroll;
    max-height: 100px;
}
.grecaptcha-badge {
    opacity: 0;
    visibility: hidden;
}

.headingTwo {
    @apply text-2xs md:text-xs xl:text-sm 2xl:text-base;
}

/* schedule global styling */
.dx-item-content.dx-scheduler-appointment-content {
    padding: 0 !important;
}
/* .dx-scheduler-header {
    z-index: 0;
} */




/* scrollbar styling */
::-webkit-scrollbar {
    width: 10px;
   height: 10px;
   
  }
  
  ::-webkit-scrollbar-track {
    background: #a1a3ef;
    
  }
  
  
  ::-webkit-scrollbar-thumb {
    background: #4a349b;
    
  }

  .heading_1{
    
        text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);  
 
    @apply text-xs drop-shadow  sm:text-sm md:text-base font-segoe-ui lg:text-[28px] xl:text-lg 2.5xl:text-xl transition-all tracking-wide hover:scale-105	
  }

  .heading_2{
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
    @apply text-2xs  md:text-xs  font-segoe-ui  xl:text-sm 2.5xl:text-lg tracking-wide transition-all	hover:scale-105
  }
  .button_font {
    @apply text-5xs md:text-6xs 2.5xl:text-[18px]
  }